(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:classroomReport
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <classroom-report></classroom-report>
       </file>
     </example>
   *
   */
  angular
    .module('components')
    .directive('classroomReport', classroomReport);

  function classroomReport() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'components/classroom-report/classroom-report-directive.tpl.html',
      replace: false,
      controllerAs: 'classroomReport',
      controller: 'ClassroomReportCtrl'
    };
  }
}());
